@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --bs-secondary: #f3e7d8;
  --bs-primary: #0e3c79;
  --light-text-color: rgb(159, 159, 159);
}

::-webkit-scrollbar {
  display: none; /* Webkit browsers */
}

.media-link {
  text-decoration: none;
  color: var(--bs-primary);
  cursor: pointer;
}

.nav-btn-link {
  text-decoration: none;
  color: black;
  width: 100%;
}

.nav-section {
  padding: 1rem 2rem;
  background-color: #f3e7d8;
}
.nav-section span {
  color: var(--light-text-color);
}

@media screen and (max-width: 570px) {
  .nav-section {
    padding: 1rem;
  }
}
* {
  font-family: "Poppins", sans-serif;
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

.header {
  margin-bottom: 78px;
}

@media screen and (max-width: 992px) {
  .header {
    margin-bottom: 74px;
  }
}
@media screen and (max-width: 570px) {
  .header {
    margin-bottom: 70px;
  }
}
/* Custom styles */
.navbar-brand img {
  height: 30px; /* Set a standard height */
  /* Width will auto-adjust to maintain aspect ratio */
}

@media (max-width: 768px) {
  .navbar-brand img {
    height: 25px; /* Adjust height for smaller screens */
  }
}
.list-group {
  border: none;
}

.list-group-item {
  border: none;
}

.list-group-item-action {
  border: none;
}

/* Banner */
.middle {
  padding: 50px 0;
  margin-top: -16px;
}

.icon {
  color: var(--light-text-color);
  border-color: var(--light-text-color);
  cursor: pointer;
}

.icon:hover {
  color: var(--bs-secondary);
}

footer {
  background-color: var(--bs-primary);
  color: var(--bs-secondary);
}
footer .footer-logo {
  width: 190px;
}
@media (max-width: 768px) {
  footer .footer-logo {
    width: 135px; /* Adjust height for smaller screens */
  }
}
footer .logo {
  color: var(--bs-secondary);
}
footer p,
footer span {
  color: var(--light-text-color);
}
footer h5 {
  font-size: 1rem;
}
footer ul li a {
  text-decoration: none;
  color: var(--light-text-color);
  font-size: 0.9rem;
  font-weight: 500;
}
footer ul li a:hover {
  color: var(--bs-secondary);
}
footer .copy-right span {
  font-size: 0.75rem;
}

.contact {
  color: var(--bs-primary);
}
.contact .colone {
  color: var(--bs-primary);
}
.contact .coltwo {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact .coltwo .contact-box {
  width: 75%;
  text-align: center;
  color: var(--bs-primary);
}
@media (max-width: 768px) {
  .contact .coltwo {
    margin-top: 40px; /* Adjust height for smaller screens */
  }
}

.categories {
  color: var(--bs-primary);
}
.categories .image-container {
  transition: transform 0.5s ease;
}
.categories .image-container:hover img {
  transform: scale(1.1);
}

.about .container {
  padding: 0px 4rem;
}
.about .container h2 {
  font-size: 2rem;
  font-weight: 700;
}
.about .container p {
  font-size: 1.1rem;
  color: rgba(0, 0, 0, 0.6);
}
@media screen and (max-width: 992px) {
  .about .container {
    padding: 0px 1rem;
  }
}

/* Customize pagination bullets */
.swiper-pagination-bullet {
  background-color: #000; /* Default bullet color */
  opacity: 1; /* Make sure bullets are fully opaque */
}

.swiper-pagination-bullet-active {
  background-color: var(--bs-primary); /* Active bullet color */
}

.rotate-hover {
  transition: transform 0.5s ease-in-out;
}

.rotate-hover:hover {
  transform: rotateY(180deg);
}/*# sourceMappingURL=styles.css.map */